import * as actions from 'stores/actions'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { ReduxState } from 'stores/models'
import { SalonStaffRole } from 'stores/models/staff.models'
import ChooseTechnicianCard from './components/choose-technician-card'
import useStyles from './styles'

const ChooseTechnician = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const styles = useStyles()
  const [selected, setSelected] = useState(props?.selected)

  const { callback, bookingServiceId, onCloseModal } = props

  const { user, salon, technicians } = useSelector((state: ReduxState) => ({
    user: state.users.user,
    salon: state.salons.salon,
    technicians: state.staffs.technicians,
  }))

  useEffect(() => {
    dispatch({ type: actions.GET_TECHNICIANS, payload: { salonId: salon?.id, role: SalonStaffRole.Technician } })
  }, [user, salon])


  const onSelected = (technician: any) => {
    setSelected(technician)
  }

  const onConfirm = () => {
    if (Number(bookingServiceId) && selected) {
      callback(Number(bookingServiceId), selected)
    } else {
      callback(selected)
    }
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        <p>{t('choose_technician')}</p>
      </div>
      <div className={styles.modalList}>
        {
          technicians?.map((technician: any) => {
            return <ChooseTechnicianCard key={technician.id} technician={technician} selected={selected} onSelected={onSelected} />
          })
        }
      </div>
      <div className={styles.modalFooter}>
        <button className={styles.modalButtonClose} onClick={() => onCloseModal()}>
          <span>{t('close')}</span>
        </button>

        <button className={styles.modalButton} onClick={onConfirm}>
          <span>{t('confirm')}</span>
        </button>
      </div>
    </div>
  )
}

export default ChooseTechnician
