// eslint-disable-next-line no-use-before-define

import './pagination.css';

import * as actions from 'stores/actions';

import { Loading, Wrapper } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from 'assets/';
import EmptyState from 'components/empty-state';
import { useTranslation } from 'react-i18next';
import { SalonAPI } from 'stores/apis';
import { ReduxState } from 'stores/models';
import { BookingStatusColor } from 'stores/models/booking.models';
import SalonModel from 'stores/models/salon.models';
import { SalonStaffRole } from 'stores/models/staff.models';
import { formatDateTz } from 'utils/times';
import BookingCard from './components/booking-card';
import useStyles from './styles';

const Home = () => {
  const dispatch = useDispatch()
  const { t, i18n }: any = useTranslation()
  const styles = useStyles()
  const navigate: any = useNavigate()
  const limit = 10

  const { salonId }: any = useParams()

  const { salon, bookings, user, staffRole, timezones, } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    bookings: state.bookings.bookings,
    user: state.users.user,
    staffRole: state.staffs.staffRole,
    timezones: state.app.timezones,
  }))

  const salonTz: any = timezones.find(item => item.id === salon?.timezoneId)

  const [state, setState] = useState({
    loading: false,
    bookingList: [],
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))

  const getSalon = async (salonId: any) => {
    if (salonId > 0) {
      handleStateChange({ loading: true })
      const response: any = await SalonAPI.getSalon(salonId)
      if (response?.data?.status === 200) {
        dispatch({ type: actions.SET_CURRENT_SALON, payload: new SalonModel(response.data.data) })
        dispatch({ type: actions.GET_TECHNICIANS, payload: { salonId, role: SalonStaffRole.Technician } })
        dispatch({ type: actions.GET_STAFF_ROLE, payload: { salonId, userId: user?.id } })
      }
      handleStateChange({ loading: false })
    }
  }

  useEffect(() => {
    if (user && salonId > 0) {
      getSalon(salonId)
    }
  }, [user, salonId])

  useEffect(() => {
    if (user && salonId > 0 && staffRole) {
      dispatch({ type: actions.GET_BOOKINGS_SUCCESS, payload: [] })
      dispatch({ type: actions.GET_BOOKINGS_REQUEST, payload: { salonId, technicianIds: Number(staffRole?.technicianId) > 0 ? [staffRole?.technicianId].join(',') : null } })
    }
  }, [user, salonId, staffRole])

  useEffect(() => {
    if (bookings?.length > 0) {
      handleStateChange({ bookingList: bookings.sort((a: any, b: any) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime()) })
    }
  }, [bookings])

  const todayBooking = () => {
    navigate(`/salons/${salonId}/today-bookings`)
  }

  const upcomingBooking = () => {
    navigate(`/salons/${salonId}/upcoming-bookings`)
  }

  const completeBooking = () => {
    navigate(`/salons/${salonId}/complete-bookings`)
  }

  const cancelledBooking = () => {
    navigate(`/salons/${salonId}/cancelled-bookings`)
  }

  const numberOfTodayBooking = bookings?.filter((booking: any) => formatDateTz(booking.startDateTime, salonTz?.timezone, 'YYYY-MM-DD') === formatDateTz(new Date(), salonTz?.timezone, 'YYYY-MM-DD')).length
  const numberOfUpcomingBooking = bookings?.filter((booking: any) => formatDateTz(booking.startDateTime, salonTz?.timezone, 'YYYY-MM-DD') > formatDateTz(new Date(), salonTz?.timezone, 'YYYY-MM-DD')).length

  return (
    <Wrapper className={styles.container}>
      {state.loading && <Loading />}
      <div className={styles.sectionList}>
        <div className={styles.sectionListHeader}>
          <div className={styles.sectionListHeaderTitle}>
            <p>{t('booking')}</p>
          </div>
        </div>

        <div className={styles.bookingStatusRow}>
          <div className={styles.bookingStatus} style={{ backgroundColor: BookingStatusColor[2]?.tagColor }} onClick={todayBooking}>
            <p className={styles.txtBookingStatus} style={{ color: BookingStatusColor[2]?.txtTagColor }}>
              {t('today')}
            </p>
            <p className={styles.txtNumber}> ({numberOfTodayBooking ?? 0})</p>
          </div>

          <div className={styles.bookingStatus} style={{ backgroundColor: BookingStatusColor[1]?.tagColor }} onClick={upcomingBooking}>
            <p className={styles.txtBookingStatus} style={{ color: BookingStatusColor[1]?.txtTagColor }}>
              {t('upcoming')}
            </p>
            <p className={styles.txtNumber}> ({numberOfUpcomingBooking ?? 0})</p>
          </div>

          <div className={styles.bookingStatus} style={{ backgroundColor: BookingStatusColor[4]?.tagColor }} onClick={completeBooking}>
            <p className={styles.txtBookingStatus} style={{ color: BookingStatusColor[4]?.txtTagColor }}>
              {t('complete')}
            </p>
            <p className={styles.txtNumber}> ({bookings?.filter((booking: any) => Number(booking.status) === Number(4))?.length ?? 0})</p>
          </div>

          <div className={styles.bookingStatus} style={{ backgroundColor: BookingStatusColor[-1]?.tagColor }} onClick={cancelledBooking}>
            <p className={styles.txtBookingStatus} style={{ color: BookingStatusColor[-1]?.txtTagColor }}>
              {t('cancelled')}
            </p>
            <p className={styles.txtNumber}> ({bookings?.filter((booking: any) => Number(booking.status) === Number(-1))?.length ?? 0})</p>
          </div>
        </div>

        <div className={styles.bookingList}>
          {state.bookingList.length > 0 ?
            <>
              {state.bookingList?.map((booking: any) => (
                <BookingCard key={booking.id} booking={booking} />
              ))}
            </>
            :
            <EmptyState imgUrl={images.bookingEmpty()} text="No bookings" />
          }
        </div>
      </div>
    </Wrapper>
  )
}

export default Home
