import './styles.css';

import { useEffect, useRef, useState } from 'react';

import { AuthAPI } from 'stores/apis';
import { BsArrowLeftShort } from 'react-icons/bs';
import { OtpInputs } from 'components/otp-inputs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface InputState {
  loading: boolean
  email: string
  confirmOtp: boolean
  error: any
}

const ForgotPassword = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const otpInputRef: any = useRef(null)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const [confirmOtp, setConfirmOtp] = useState(false)
  const [seconds, setSeconds] = useState(60)
  const [code, setCode] = useState('')

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [error, setError] = useState('')

  useEffect(() => {
    if (confirmOtp) {
      const interval = seconds > 0 ? setInterval(() => {
        setSeconds(seconds - 1)
      }, 1000) : null

      return () => {
        if (interval) clearInterval(interval)
      }
    }
  }, [seconds, confirmOtp])

  const onBack = () => {
    navigate('/login')
  }

  const onResendEmailOtp = async () => {
    setSeconds(60)
    const response: any = await AuthAPI.sendForgetPasswordEmailOtp({ email })

    if (response?.data?.status === 200 && response?.data?.data) {
      // 'OTP has been sent. Please check your mailbox.'      
    } else {
      setError(response?.data?.data?.message)
    }
  }

  const onPushVerifyEmailOtp = async () => {
    setError('')
    setLoading(true)
    const response: any = await AuthAPI.sendForgetPasswordEmailOtp({ email })

    if (response?.data?.status === 200 && response?.data?.data) {
      setConfirmOtp(true)
    } else {
      setError(response?.data?.data?.message)
    }
    setLoading(false)
  }

  const onChangeCode = (c: string) => {
    setCode(c)
    if (c.length === 5) {
      onSetNewPassword(c)
    }
  }

  const onSetNewPassword = async (c: string = code) => {
    setError('')
    const response: any = await AuthAPI.checkEmailOtp({ email: email, otp: Number(c) })
    if (response?.data?.status === 200) {
      setShowNewPassword(true)
      setConfirmOtp(false)
    } else {
      setError(response?.data?.data?.message ?? 'Wrong OTP code. Please re-enter.')
      otpInputRef.current?.reset()
      setCode('')
    }
  }

  const onResetPassword = async () => {
    setError('')
    if (password !== confirmPassword) {
      setError('Password and confirm password does not match')
      return
    } else if (password.trim().length < 6) {
      setError('Password must be at least 6 characters')
      return
    }

    setLoading(true)
    const response: any = await AuthAPI.resetPassword({ email: email, otp: Number(code), newPassword: password.trim() })
    if (response?.data?.status === 200) {
      setError('Password reset successful. Please use the new password to login.')
      onBack()
    } else {
      setError(response?.data?.data?.message ?? 'An error occurred. Please try again.')
    }
    setLoading(false)
  }


  return (
    <div className="container">
      <div className="content">
        <div className="login-page height90">
          <div className="back" onClick={() => navigate(-1)}>
            <BsArrowLeftShort size={28} />
          </div>

          <div className="error text-red text-center">
            {error}
          </div>

          {!confirmOtp && !showNewPassword &&
            <>
              <div className="form-group">
                <label htmlFor="email">{t('forgot_password_description')}</label>
                <input type="email" className="form-control" name="username" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>

              <div className="form-group text-center">
                <button type="button" className="btn btn-login" onClick={onPushVerifyEmailOtp}>{t('forgot_password_send_otp')}</button>
              </div>
            </>
          }

          {confirmOtp && !showNewPassword &&
            <div className="form-group">
              <label htmlFor="email">{t('forgot_password_description')}</label>
              <div className="resend">
                <span className="resend-text">{t('forgot_password_expires')}</span>
                {seconds > 0 ?
                  <span className="resend-second">{t('resend')} ({seconds}s)</span>
                  :
                  <span className="resend-button" onClick={onResendEmailOtp}>Resend</span>
                }
              </div>
              <OtpInputs ref={otpInputRef} onChange={onChangeCode} />
            </div>
          }

          {showNewPassword &&
            <>
              <div className="form-group">
                <label htmlFor="password">{t('enter_new_password')}</label>
                <input type="password" className="form-control mg10" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <input type="password" className="form-control mg10" name="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>

              <div className="form-group text-center">
                <button type="button" className="btn btn-login" onClick={onResetPassword}>{t('reset_password')}</button>
              </div>
            </>
          }

        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
