// eslint-disable-next-line no-use-before-define

import './pagination.css'

import * as actions from 'stores/actions'

import SalonModel, { SalonTypeNames } from 'stores/models/salon.models'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import EmptyState from 'components/empty-state'
import { ReduxState } from 'stores/models'
import { SalonAPI } from 'stores/apis'
import { SalonStaffRole } from 'stores/models/staff.models'
import { Wrapper } from 'components'
import images from 'assets'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'
import { useTranslation } from 'react-i18next'

const Salons = () => {
  const dispatch = useDispatch()
  const { t, i18n }: any = useTranslation()
  const styles = useStyles()
  const navigate: any = useNavigate()
  const limit = 10
  const { salons, user } = useSelector((state: ReduxState) => ({
    salons: state.salons.salons,
    user: state.users.user,
  }))


  const [state, setState] = useState({
    filterTab: 0,
    page: 0,
    limit,
    showLangOptions: false,
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))

  useEffect(() => {
    if (user) {
      dispatch({ type: actions.GET_SALONS_REQUEST, payload: { roles: [SalonStaffRole.Admin, SalonStaffRole.Cashier, SalonStaffRole.Technician].join(',') } })
    }
  }, [user])

  const goSalon = async (salon: any) => {
    const response: any = await SalonAPI.getSalon(salon.id)
    if (response?.data?.status === 200) {
      dispatch({ type: actions.SET_CURRENT_SALON, payload: new SalonModel(response.data.data) })
      dispatch({ type: actions.GET_TECHNICIANS, payload: { salonId: salon.id, role: SalonStaffRole.Technician } })
      navigate(`/salons/${salon.id}/bookings`)
    } else {
      console.log("error", response.data.message)
    }
  }

  return (
    <Wrapper className={styles.container}>
      <div className={styles.salonsList}>
        <div className={styles.sectionListHeader}>
          <div className={styles.sectionListHeaderTitle}>
            <p>{t('salon')}</p>
          </div>
        </div>
        {salons.length > 0 ?
          <>
            {salons?.map((salon: any) => (
              <div className={styles.salonsListItem} key={salon?.id} onClick={() => goSalon(salon)}>
                <div className={styles.salonLogo}>
                  <img className={styles.salonLogoImg} src={salon?.logoUrl} alt={salon.name} width="100%" height="100%" />
                </div>
                <div className={styles.salonInfo}>
                  <div>
                    <span className={styles.salonCategory}>{SalonTypeNames[salon?.type ?? 1]}</span>
                  </div>
                  <div className={styles.salonName}>{salon?.name}</div>
                  <div className={styles.salonAddress}>{salon?.location?.address}</div>
                </div>
              </div>
            ))}
          </>
          :
          <EmptyState imgUrl={images.logo()} text={t('no_salon')} />
        }
      </div>
    </Wrapper>
  )
}

export default Salons
