import * as actions from "stores/actions";

import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { envConfigs } from "configs";
import i18next from 'i18next';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { BsArrowLeftShort } from 'react-icons/bs';
import { ReduxState } from "stores/models";
import { localStorageUtils } from "utils";
import { LocalStorageValueTypes } from 'utils/localStorage';
import { modalRef } from "utils/refs";
import useStyles from "./styles";

export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}
interface State {
  lang: Language
}

const LogoutModalContent = ({ t, styles }: any) => {
  const dispatch = useDispatch()

  const logout = () => {
    dispatch({ type: actions.LOG_OUT })
    localStorageUtils.clear()
    window.location.href = `${envConfigs.WEB_URL}/login`
  }

  const onCloseModal = () => modalRef?.current?.close()

  return (
    <div>
      <h6>{t('are_you_sure_logout')}?</h6>
      <div className={styles.logoutBtns}>
        <button onClick={onCloseModal} className={styles.btnNo}>
          {t('cancel')}
        </button>
        <button onClick={logout} className={styles.btnYes}>
          {t('logout')}
        </button>
      </div>
    </div>
  )
}

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const styles = useStyles()
  const { t }: any = useTranslation()

  const user = useSelector((state: ReduxState) => state.users.user)

  const [state, setState] = useState<State>({
    lang: Language.English,
  })

  const handleStateChange = (value: any, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  if (!user) return null

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    console.log("language: ", lang)
    if (lang) {
      i18next.changeLanguage(lang)
      handleStateChange(lang, 'lang')
    }
  }, [])

  const onOpenLogoutModal = () => {
    modalRef?.current?.open({
      content: <LogoutModalContent t={t} styles={styles} />,
    })
  }

  const onSwitchingLanguage = () => {
    const newLang = state.lang === 'vi' ? 'en' : 'vi'
    handleStateChange(newLang, 'lang')
    i18next.changeLanguage(newLang)
    moment.locale(newLang)
    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, newLang)
  }

  const showBackButton = envConfigs.WEB_URL + '/' !== window.location.href;

  return (
    <div className={styles.container}>
      {showBackButton &&
        <div className={styles.back} onClick={() => navigate(-1)}>
          <BsArrowLeftShort size={36} />
        </div>
      }
      <div className={styles.users}>
        <div className="dropdown" style={{ marginLeft: '10px' }}>
          <div data-bs-toggle="dropdown" aria-expanded="false">
            <img className={styles.userAvatar} src={user?.avatarUrl} alt="avatar" />
          </div>
          <ul className="dropdown-menu">
            <li><span className={`dropdown-item ${styles.txtName}`}>{t('hi')}, {user?.name}</span></li>
            <li><span className={`dropdown-item ${styles.txtLogout}`} onClick={onOpenLogoutModal}>{t('logout')}</span></li>
          </ul>
        </div>
      </div>
      <Link to='#' className={styles.languageSection} onClick={onSwitchingLanguage}>
        {state.lang === 'en' ? <span className={`flag-icon flag-icon-gb`}></span> : <span className={`flag-icon flag-icon-vn`}></span>}
      </Link>
    </div>
  )
}

export default Header
