import { BsCheck2, BsClock } from 'react-icons/bs';

import { useSelector } from 'react-redux';
import { ReduxState } from 'stores/models';
import { formatCurrency } from 'utils/currency';
import useStyles from './styles';

const ChooseServicesCard = (props: any) => {
  const styles = useStyles()

  const { salon } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
  }))

  const { service, selected, onSelected } = props

  return (
    <div className={`${styles.serviceInfo} ${Number(selected?.id) === Number(service?.id) ? styles.techInfoSelected : ''}`} onClick={() => onSelected(service)}>
      <div className={styles.techName}>
        {service?.name}
      </div>
      <div className={styles.rowDurationPrice}>
        <div className={styles.durationBox}>
          <BsClock size={18} />
          <span className={styles.duration}>{service?.duration} m</span>
        </div>
        <div className={styles.priceBox}>
          <span className={styles.price}>{formatCurrency(service?.price?.toString(), salon?.timezone?.currency)}</span>
          {service?.refPrice > 0 && <span className={styles.refPrice}>({formatCurrency(service?.refPrice?.toString(), salon?.timezone?.currency)})</span>}
        </div>
      </div>
      {
        Number(selected?.id) === Number(service?.id) ? <span className={styles.checked}><BsCheck2 /></span> : null
      }
    </div>
  )
}

export default ChooseServicesCard