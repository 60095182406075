import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    margin: '0 auto',
    maxWidth: '800px',
    minWidth: '300px',
    padding: '0 50px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    '@media (max-width: 768px)': {
      padding: '0 20px',
    },
  },

  f1: {
    flex: 1,
  },

  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: '15px 0 20px',
    borderBottom: '1px solid #dfe3e9',
  },

  customerInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  customerName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },

  customerPhone: {
    display: 'flex',
    fontSize: '14px',
  },

  customerAvatar: {
    width: '72px',
    height: '72px',
    borderRadius: '12px',
  },

  customerAvatarImg: {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
  },

  //section list
  sectionList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0 0 20px',
  },

  contentRow: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0 0',
  },

  contentRowInline: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0 6px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  rowTitle: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0 5px',
    fontSize: '12px',
    fontWeight: '700',
    color: '#858585',
    flex: 1,
  },

  rowContent: {
    fontSize: '16px',
    color: '#3C3C3E',
    fontWeight: '700',
  },

  rowContentRight: {
    fontSize: '16px',
    color: '#3C3C3E',
    fontWeight: '700',
    textAlign: 'right',
  },

  //booking service card
  bookingServices: {
    display: 'flex',
    flexDirection: 'column',
  },

  serviceRowName: {
    display: 'flex',
    flexDirection: 'row',
  },

  serviceName: {
    fontSize: '16px',
    fontWeight: '700',
    color: '#3C3C3E',
  },

  serviceInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },

  techInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  techAvatar: {
    width: '36px',
    height: '36px',
    borderRadius: '12px',
    marginRight: '10px',
  },

  techAvatarImg: {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
  },

  techName: {
    fontSize: '14px',
    fontWeight: '700',
    color: '#3C3C3E',
  },

  serviceDuration: {
    fontSize: '14px',
    fontWeight: '700',
    color: '#3C3C3E',
  },

  addService: {
    display: 'flex',
    color: '#578688',
    fontWeight: '700',
  },

  addExtraService: {
    display: 'flex',
    color: '#578688',
    fontWeight: '700',
    marginRight: '1rem',
  },

  // technician Modal List
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 0 20px',
    fontWeight: '700',
    color: '#578688',
    borderBottom: '1px solid #578688',
    marginBottom: '20px',
    fontSize: '20px',
  },

  modalList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '700px',
  },

  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px 20px',
    borderTop: '1px solid #578688',
    marginTop: '20px',
  },

  modalButton: {
    display: 'flex',
    padding: '10px 15px',
    fontWeight: '700',
    borderRadius: '7px',
    color: '#fff',
    backgroundColor: '#578688',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    marginLeft: '15px',
  },

  modalButtonClose: {
    display: 'flex',
    padding: '10px 15px',
    fontWeight: '700',
    borderRadius: '7px',
    color: '#578688',
    backgroundColor: '#fff',
    border: '1px solid #578688',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },

  //footer
  sectionFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #dfe3e9',
    padding: '15px 0',
    marginTop: '20px',
  },

  //promotion
  rowCheckBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '15px',
    fontWeight: '700',
  },

  checkBox: {
    '&:checked': {
      backgroundColor: '#578688 !important',
      borderColor: '#578688 !important',
    },
  },

  groupPromotion: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  promotion: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '45px !important',
  },

  chargeDiscount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '10px 15px',
    fontWeight: '700',
    cursor: 'pointer',
    position: 'absolute',
    right: '0',
    borderLeft: '1px solid #dfe3e9',
  },

  //confirm modal
  confirmContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px',
  },

  //group button
  groupButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },

  buttonConfirm: {
    display: 'flex',
    padding: '10px 20px',
    fontWeight: '700',
    borderRadius: '7px',
    color: '#fff',
    backgroundColor: '#578688',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    marginLeft: '1.8rem',
  },

  buttonCancel: {
    display: 'flex',
    padding: '10px 20px',
    fontWeight: '700',
    borderRadius: '7px',
    color: '#578688',
    backgroundColor: '#fff',
    border: '1px solid #578688',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },

  //promotionValueOptions
  promotionValueOptionTitle: {
    display: 'flex',
    color: '#578688',
    fontWeight: '700',
  },
  promotionValueOptions: {
    display: 'flex',
    flexDirection: 'column',
  },

  promotionValueOption: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    borderBottom: '1px dashed #dfe3e9',
    cursor: 'pointer',
  },

  //comments
  comments: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
  },

  comment: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px dashed #dfe3e9',
    padding: '10px 0',
    alignItems: 'center',
    marginBottom: '10px',
  },

  commentAvatar: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    border: '1px dashed #dfe3e9',
  },

  commentAvatarImg: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },

  commentBody: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginLeft: '10px',
  },

  commentContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: '10px',
    marginLeft: '10px',
  },

  commentContentText: {
    backgroundColor: '#F3F6F6',
    padding: '15px',
    borderRadius: '10px',
    marginBottom: '10px',
  },

  commentText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '5px',
  },

  commentImages: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  commentImage: {
    width: '100px',
    borderRadius: '10px',
    marginRight: '10px',
  },

  commentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },

  commentAuthor: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    fontWeight: '500',
    flex: 1,
  },

  commentDate: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    fontStyle: 'italic',
  },
  //chat
  messageInput: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-end',
    marginBottom: '20px',
  },

  fileInput: {
    display: 'none',
  },

  groupInput: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: '10px',
  },

  viewImages: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
  },

  imageItem: {
    display: 'flex',
    position: 'relative',
  },

  deleteImage: {
    display: 'flex',
    position: 'absolute',
    right: '10px',
    top: '-10px',
    borderRadius: '50%',
    border: '1px solid #b2b0b0',
    color: '#b2b0b0',
    cursor: 'pointer',
  },

  image: {
    width: '100px',
    padding: '5px',
    marginRight: '20px',
    borderRadius: '5px',
    border: '1px dashed #dfe3e9',
  },

  messageInputText: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    borderRadius: '7px',
    border: '1px solid #dfe3e9',
    padding: '10px',
    outline: 'none',
    fontSize: '14px',
    fontWeight: '700',
    color: '#3C3C3E',
  },
})

export default useStyles
