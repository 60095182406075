import { Colors } from 'constants/colors.constants';
import { createUseStyles } from 'react-jss';

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  input: {
    width: '6 0px !important',
    height: '60px !important',
    textAlign: 'center !important',
    fontSize: '24px !important',
    color: Colors.PRIMARY,
    '&:focus': {
      border: '2px solid #578688',
      outline: 'none'
    },
  }
})

export default useStyles