import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: '100%',
    margin: '0 auto',
    maxWidth: '800px',
    minWidth: '300px',
    padding: '0 50px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    '@media (max-width: 768px)': {
      padding: '0 20px',
    },
  },

  content: {
    display: 'flex',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px'
  },

  title: {
    fontSize: '30px'
  },

  select: {
    padding: '5px 10px',
    borderColor: '#dfe3e9',
    borderRadius: '5px'
  },

  sectionHeader: {
    display: 'flex',
  },

  sectionList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  sectionListHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0',
  },

  sectionListHeaderTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
  },

  // booking card
  bookingList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  bookingListItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '15px',
    cursor: 'pointer',
  },

  bookingLeftInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '15px',
    justifyContent: 'center',
  },

  customerAvatar: {
    width: '36px',
    height: '36px',
    borderRadius: '15px',
  },

  customerAvatarImg: {
    width: '100%',
    height: '100%',
  },

  duration: {
    fontWeight: 'bold',
  },

  bookingRightInfo: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    borderRadius: '15px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },

  groupBookingInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '15px 10px',
  },

  bookingTechRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  groupTechName: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
  },

  techAvatar: {
    width: '25px',
    height: '25px',
    borderRadius: '15px',
  },

  techAvatarImg: {
    width: '100%',
    height: '100%',
  },

  bookingTechName: {
    marginLeft: '10px',
  },

  bookingTechPhone: {
    marginLeft: '10px',
  },

  bookingNote: {
    fontWeight: 'bold',
    paddingTop: '10px',
  },

  rectangle: {
    width: '8px',
    height: '100%',
    backgroundColor: '#dfe3e9',
    borderRadius: '15px 0 0 15px',
  },

  bookingStatusRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: '1.5rem',
    padding: '20px 0',
  },

  bookingStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ECCD99',
    padding: '4px 8px',
    borderRadius: '5px',
    width: '120px',
    textAlign: 'center',
    zIndex: 5,
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      width: 'max-content',
    },
  },

  txtNumber: {
    fontWeight: 'bold',
    fontSize: '12px',
    paddingLeft: '5px',
    '@media (max-width: 768px)': {
      paddingLeft: '0px',
    },
  },

  txtBookingStatus: {
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },

  //tab
  tabs: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  tabList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  tab: {
    border: '1px solid #578688',
    borderBottom: 'none',
    borderRadius: '5px 5px 0 0',
    background: '#fff',
  },

  selectedClassName: {
    display: 'flex',
    flex: '1',
  }
})

export default useStyles
