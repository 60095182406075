import images from "assets"
import { envConfigs } from 'configs'
import CategoriesModel from "./categories.models"
import SalonImageModel from "./salon-image.models"
import SalonWorkingTimeModel from "./salon-working-time.models"
import ServiceModel from "./service.models"
import TechnicianServiceModel from "./technician-service.models"
import StaffModel from "./technician.models"

export enum SalonTypes {
  BeautySalon = 1,
  CarSalonGarage = 2,
  HouseCare = 3,
  EntertainmentAds = 4,
  Freelancer = 5
}

export const SalonTypeNames: any = {
  1: "Beauty",
  2: "Car Salon & Garage",
  3: "House Care",
  4: "Entertainment Ads",
  5: "Freelancer"
}

export default class SalonModel {
  public id!: number
  public reference_code!: number
  public location_id!: number
  public timezoneId!: number
  public type!: number
  public name!: string
  public email!: string
  public phone!: string
  public website!: string
  public description!: string
  public actualAddress!: string
  public createdBy!: number
  public createdAt!: number
  public updatedAt!: number
  public images!: SalonImageModel[]
  public workingTime!: SalonWorkingTimeModel[]
  public location!: any
  public categories!: CategoriesModel[]
  public services!: ServiceModel[]
  public staffs!: StaffModel[]
  public techniciansServices!: TechnicianServiceModel[]
  public timezone!: any

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.reference_code = data.reference_code
      this.location_id = data.location_id
      this.timezoneId = data.timezone_id
      this.type = data.type
      this.name = data.name
      this.phone = data.phone
      this.email = data.email
      this.phone = data.phone
      this.website = data.website
      this.description = data.description
      this.actualAddress = data.actualAddress
      this.createdBy = data.created_by
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      this.timezone = data.timezone

      if (data.images) {
        this.images = data.images.map((item: SalonImageModel) => new SalonImageModel(item))
      }
      if (data.working_time) {
        this.workingTime = data.working_time.map((item: SalonWorkingTimeModel) => new SalonWorkingTimeModel(item))
      }

      this.location = data.location

      if (data.categories) {
        this.categories = data.categories.map((item: CategoriesModel) => new CategoriesModel(item))
      }
      if (data.services) {
        this.services = data.services.map((service: ServiceModel) => new ServiceModel(service))
      }
      if (data.staffs) {
        this.staffs = data.staffs.map((technician: StaffModel) => new StaffModel(technician))
      }
      if (data.techniciansServices) {
        this.techniciansServices = data.techniciansServices.map((technicianService: TechnicianServiceModel) => new TechnicianServiceModel(technicianService))
      }
    }
  }

  public get slug() {
    return `${this.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')}-${this.reference_code}.html`
  }

  public get url() {
    return `${envConfigs.IMAGE_LINK}/salons`
  }

  public get logoUrl(): string {
    if (this.images && this.images.length > 0) {
      const logoImage = this.images.find((image: SalonImageModel) => image.type === 1)
      if (logoImage) {
        return `${envConfigs.IMAGE_LINK}/salons/${logoImage.thumbnail}`
      }
    }
    return images.salonLogoDefault()
  }
}