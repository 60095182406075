import BookingServiceModel from "./booking-service.models"

export enum BookingStatus {
  Canceled = -1,
  Pending = 1,
  Confirmed = 2,
  CheckedIn = 3,
  Done = 4,
}

export const BookingStatusNames: any = {
  '-1': 'cancelled',
  1: 'pending',
  2: 'confirmed',
  3: 'checked_in',
  4: 'done',
}

export const BookingStatusButton: any = {
  '-1': 'Reject',
  1: 'Pending',
  2: 'Confirm',
  3: 'Check In',
  4: 'Check out',
}

export const BookingStatusColor: any = {
  '-1': {
    bgColor: '#FFFFFF',
    tagColor: '#D2D2D2',
    txtTagColor: '#FA5E13',
    recColor: '#D2D2D2',
  },
  1: {
    bgColor: '#FFFAED',
    tagColor: '#ECCD99',
    txtTagColor: '#3C3C3E',
    recColor: '#ECCD99',
  },
  2: {
    bgColor: '#FFFFFF',
    tagColor: '#CCE0E0',
    txtTagColor: '#3C3C3E',
    recColor: '#CCE0E0',
  },
  3: {
    bgColor: '#FFFFFF',
    tagColor: '#9CC0B7',
    txtTagColor: '#3C3C3E',
    recColor: '#9CC0B7',
  },
  4: {
    bgColor: '#FFFFFF',
    tagColor: '#D2D2D2',
    txtTagColor: '#3C3C3E',
    recColor: '#D2D2D2',
  },
}
export const BookingPromotionType: any = {
  Percent: 1,
  Value: 2,
}

export const BookingPromotionTypeName: any = {
  1: 'Percent (%)',
  2: 'Price ($)',
}

export default class BookingModel {
  public id!: number
  public userId!: number
  public salonId!: number
  public status!: number
  public name!: string
  public phone!: string
  public paxNumber!: number
  public startDateTime!: string
  public type!: number
  public note!: string
  public promotionType!: number
  public promotionValue!: number
  public surcharge!: number
  public tip!: number
  public createdAt!: number
  public updatedAt!: number
  public bookingCode!: any

  public bookingServices!: BookingServiceModel[]
  public bookingStatuses!: any
  public bookingReferenceId!: any

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.userId = data.user_id
      this.salonId = data.salon_id
      this.status = data.status
      this.name = data.name
      this.phone = data.phone
      this.paxNumber = data.pax_number
      this.startDateTime = data.start_date_time
      this.type = data.type
      this.note = data.note
      this.promotionType = data.promotion_type
      this.promotionValue = data.promotion_value
      this.surcharge = data.surcharge
      this.tip = data.tip
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      this.bookingCode = data.booking_code
      if (data?.booking_services) {
        this.bookingServices = data?.booking_services?.map((item: BookingServiceModel) => new BookingServiceModel(item))
      }
      this.bookingStatuses = data.booking_statuses
      this.bookingReferenceId = data?.booking_reference_id
    }
  }

}