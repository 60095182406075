import * as actions from '../actions'

import { AppState } from 'stores/models/redux-state.models'
import TimezoneModel from 'stores/models/timezone.models'

const initState: AppState = {
  timezones: [],
}

const app = (state = initState, action: any) => {
  switch (action.type) {
    case actions.GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: action.payload.map((t: any) => new TimezoneModel(t))
      }

    case actions.LOG_OUT:
      return initState

    default:
      return state
  }
};

export default app