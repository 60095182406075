import React, { useImperativeHandle, useState } from 'react'

import useStyles from './styles'

interface Props {
  onChange: (code: string) => void,
}

export const OtpInputs = React.forwardRef(({ onChange }: Props, ref: any) => {
  const styles = useStyles()

  const [code1, setCode1] = useState('')
  const [code2, setCode2] = useState('')
  const [code3, setCode3] = useState('')
  const [code4, setCode4] = useState('')
  const [code5, setCode5] = useState('')
  const inputRef1: any = React.createRef()
  const inputRef2: any = React.createRef()
  const inputRef3: any = React.createRef()
  const inputRef4: any = React.createRef()
  const inputRef5: any = React.createRef()

  useImperativeHandle(ref, () => ({
    reset: (): void => {
      setCode1('')
      setCode2('')
      setCode3('')
      setCode4('')
      setCode5('')
      inputRef1?.current?.focus()
    }
  }), [0])

  const onChangeText1 = (e: any) => {
    const code = e.target.value
    setCode1(code)
    if (code) {
      inputRef2?.current?.focus()
      onChange(`${code}${code2}${code3}${code4}${code5}`)
    }
  }

  const onChangeText2 = (e: any) => {
    const code = e.target.value
    setCode2(code)
    if (code) {
      inputRef3?.current?.focus()
      onChange(`${code1}${code}${code3}${code4}${code5}`)
    } else {
      inputRef1?.current?.focus()
    }
  }

  const onChangeText3 = (e: any) => {
    const code = e.target.value
    setCode3(code)
    if (code) {
      inputRef4?.current?.focus()
      onChange(`${code1}${code2}${code}${code4}${code5}`)
    } else {
      inputRef2?.current?.focus()
    }
  }

  const onChangeText4 = (e: any) => {
    const code = e.target.value
    setCode4(code)
    if (code) {
      inputRef5?.current?.focus()
      onChange(`${code1}${code2}${code3}${code}${code5}`)
    } else {
      inputRef3?.current?.focus()
    }
  }

  const onChangeText5 = (e: any) => {
    const code = e.target.value
    setCode5(code)
    if (code) {
      onChange(`${code1}${code2}${code3}${code4}${code}`)
    } else {
      inputRef4?.current?.focus()
    }
  }

  return (
    <div className={styles.container}>
      <input value={code1} autoFocus maxLength={1} ref={inputRef1} className={styles.input} onChange={onChangeText1} />
      <input value={code2} maxLength={1} ref={inputRef2} className={styles.input} onChange={onChangeText2} />
      <input value={code3} maxLength={1} ref={inputRef3} className={styles.input} onChange={onChangeText3} />
      <input value={code4} maxLength={1} ref={inputRef4} className={styles.input} onChange={onChangeText4} />
      <input value={code5} maxLength={1} ref={inputRef5} className={styles.input} onChange={onChangeText5} />
    </div>
  )
})
