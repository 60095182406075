import * as actions from 'stores/actions'

import { Route, Routes } from "react-router-dom"

import Booking from 'containers/bookings/booking-detail'
import CancelledBookings from 'containers/bookings/cancelled-bookings'
import CompleteBookings from 'containers/bookings/complete-bookings'
import Home from 'containers/home'
import { LocalStorageValueTypes } from "utils/localStorage"
import NotFound from 'containers/not-found'
import Salons from './containers/salons'
import TodayBookings from 'containers/bookings/today-bookings'
import UpcomingBookings from 'containers/bookings/upcoming-bookings'
import UserModel from 'stores/models/user.models'
import { UsersAPI } from 'stores/apis'
import { envConfigs } from "configs"
import gateway from 'stores/apis/gateway'
import i18next from "i18next"
import { localStorageUtils } from "utils"
import { useDispatch } from "react-redux"
import { useEffect } from "react"

function AuthenticatedApp() {
  const dispatch = useDispatch()

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    if (lang) {
      // i18next.changeLanguage(lang)
    }
    checkAuthorized()
  }, [])

  const saveUser = (data: any) => {
    dispatch({ type: actions.SET_USER, payload: new UserModel(data) })
    // localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserData, JSON.stringify(data))

    // localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, data.Language)
    // i18next.changeLanguage(data?.Language)
  }

  const authLogin = async () => {
    // get user by token
    const token = localStorageUtils.getLocalStorage(LocalStorageValueTypes.AccessToken)
    if (!token) return false

    gateway.init(JSON.parse(token))
    const response: any = await UsersAPI.getUserProfile()
    const { data } = response

    if (data?.isSuccess) {
      dispatch({ type: actions.GET_TIMEZONES_REQUEST })
      saveUser(data?.data)
    }

    dispatch({ type: actions.SET_AUTH_LOADING, payload: false })
    return data?.isSuccess
  }

  const checkAuthorized = async () => {
    dispatch({ type: actions.SET_AUTH_LOADING, payload: true })
    const isLogin = await authLogin()

    if (!isLogin) {
      onLogout()
      window.location.href = `${envConfigs.WEB_URL}/login`
    }

    dispatch({ type: actions.SET_AUTH_LOADING, payload: false })
  }

  const onLogout = async () => {
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.AccessToken)
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserData)
    window.location.href = `${envConfigs.WEB_URL}/logout.html`
  }

  return (
    <Routes>
      <Route path="/" element={<Salons />} />
      <Route path="/salons/:salonId/bookings" element={<Home />} />
      <Route path="/salons/:salonId/today-bookings" element={<TodayBookings />} />
      <Route path="/salons/:salonId/upcoming-bookings" element={<UpcomingBookings />} />
      <Route path="/salons/:salonId/complete-bookings" element={<CompleteBookings />} />
      <Route path="/salons/:salonId/cancelled-bookings" element={<CancelledBookings />} />
      <Route path="/salons/:salonId/bookings/:bookingId" element={<Booking />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AuthenticatedApp