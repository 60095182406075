
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setHours, setMinutes } from "date-fns"
import moment from 'moment-timezone'
import { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { useTranslation } from 'react-i18next'
import { ReduxState } from 'stores/models'
import { subTime } from 'utils/times'
import useStyles from './styles'

interface InputState {
  name: string
  price: any
  duration: string
  technician: any
}

const defaultWeekdays = [1, 2, 3, 4, 5, 6, 7];
const lastDayOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

const ChangeSchedule = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const styles = useStyles()

  const { salon, user, timezones } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    user: state.users.user,
    timezones: state.app.timezones,
  }))
  const salonTz: any = timezones.find(item => item.id === salon?.timezoneId)

  const [schedule, setSchedule] = useState<Date | null>(new Date())
  const [minDate, setMinDate] = useState<Date | null>(new Date())
  const [minTime, setMinTime] = useState<any>('00:00')
  const [maxTime, setMaxTime] = useState<any>('00:00')
  const [disabledDates, setDisabledDates] = useState<any>([])

  const { callback, onCloseModal } = props

  useEffect(() => {
    if (salon) {
      const salonWeekdays = salon.workingTime.map((item: any) => item.weekday)
      const disabledWeekdays = defaultWeekdays.filter((item: any) => !salonWeekdays.includes(item))

      const day: any = schedule?.getDate()
      const month: any = schedule?.getMonth()
      const year: any = schedule?.getFullYear()
      const lastDay = lastDayOfMonth[month]

      const disabledDates = []
      let newSchedule = schedule
      for (let i: any = day; i <= lastDay; i++) {
        const itemDate = new Date(year, month, i)
        const itemWeekDay = itemDate.getDay()
        if (disabledWeekdays.includes(itemWeekDay)) {
          disabledDates.push(new Date(year, month, i))

          if (moment(newSchedule).format('YYYY-MM-DD') === moment(itemDate).format('YYYY-MM-DD')) {
            newSchedule = new Date(year, month, i + 1)
          }
        }
      }


      setDisabledDates(disabledDates)
      if (newSchedule) {
        const isWorking = salon.workingTime.find((item: any) => item.weekday === newSchedule?.getDay())
        if (isWorking) {
          const startTime: any = isWorking.from.split(':')
          const endTime: any = subTime(isWorking.to, '01:00')?.split(':')
          const newMinTime = setHours(setMinutes(newSchedule, startTime[1]), startTime[0])
          const newMaxTime = setHours(setMinutes(newSchedule, endTime[1]), endTime[0])
          newSchedule = newMinTime

          setMinTime(newMinTime ?? '00:00')
          setMaxTime(newMaxTime ?? '00:00')
        }

        setSchedule(newSchedule)
        setMinDate(newSchedule)
      }
    }
  }, [salon])

  const onConfirm = () => {
    if (schedule) {
      callback(schedule)
    }
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        {t('choose_schedule')}
      </div>
      <div style={{ textAlign: 'center' }}>
        <DatePicker
          showTimeSelect
          timeFormat="HH:mm"
          selected={schedule}
          onChange={(date: any) => setSchedule(date)}
          excludeDates={disabledDates}
          placeholderText="Select a date other than today or yesterday"
          dateFormat="dd/MM/yyyy HH:mm"
          minDate={minDate}
          minTime={minTime}
          maxTime={maxTime}
        />
      </div>

      <div className="form-group text-center">
        <button type="button" className="btn btn-login" onClick={onConfirm}>{t('confirm')}</button>
      </div>
    </div>
  )
}

export default ChangeSchedule
