export enum Colors {
  RED = '#E1222A',
  PRIMARY = '#578688',
  ORANGE = '#EB942E',
  BLUE = '#4B96DD',
  GREEN = '#619F60',
  BLACK1 = '#151416',
  BLACK2 = '#171515',
  DARKGRAY = '#666463',
  DARKGRAY8 = '#858585',
  GRAY = '#B2B0B0',
  GRAYD = '#DFDFDF',
  LIGHTGRAY = '#E5E2E2',
  WHITE = '#fff',
  BGGRAY = '#F2EFEF',
  BLACK = '#000000',
  BGGREEN = '#49AE6B',
  BGORANGE = '#EFA129',
  BGRED = '#E12828'
}

export const AvatarColors: any = {
  '1': '#1769aa',
  '2': '#618833',
  '3': '#cddc39',
  '4': '#ff9800',
  '5': '#673ab7',
  '6': '#ff5722',
  '7': '#B2B0B0',
}