import { TechnicianAvatar } from 'components/technician-avatar'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { ReduxState } from 'stores/models'
import { formatCurrency } from 'utils/currency'
import { minutesToHours } from 'utils/times'
import useStyles from './styles'

interface InitState {
  isShowMenu: any,
  isShowMenuEdit: any
}

const BookingServiceCard = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { salon, technicians } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    technicians: state.staffs.technicians,
  }))

  const { item, openModalChangeTechnician, onDeleteBookingService, disabledDeleteService } = props
  const technician = technicians?.find((t: any) => t.id === item?.technicianId)
  const price = item?.price || 0
  const refPrice = item?.refPrice || 0

  return (
    <>
      <div key={item?.id} className={styles.bookingServices}>
        <div className={styles.serviceRowName}>
          <div className={styles.f1}>
            <span className={styles.serviceName}>{item?.name}</span> <span className={styles.serviceDuration}>- {minutesToHours(Number(item?.duration))}</span>
          </div>

          {!disabledDeleteService &&
            <div className={styles.deleteService} onClick={() => onDeleteBookingService(item?.id)}>
              <MdClose size={20} color={'#ff0000'} />
            </div>
          }
        </div>
        <div className={styles.serviceInfo}>
          <div className={styles.techInfo}>
            <div className={styles.techAvatar}>
              <TechnicianAvatar avatarUrl={technician ? technician?.avatarUrl() : null} technician={technician} style={styles.techAvatar} />
            </div>
            <div className={styles.techName} onClick={!disabledDeleteService ? () => openModalChangeTechnician(Number(item?.id), technician) : undefined}>
              {technician?.name ? technician?.name : t('choose_technician')}
            </div>
          </div>
          <div className={styles.priceBox}>
            <div className={styles.price}>
              {formatCurrency(price.toString(), salon?.timezone?.currency)}
            </div>
            {refPrice > 0 &&
              <div className={styles.refPrice}>
                ({formatCurrency(price?.toString(), salon?.timezone?.currency)})
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default BookingServiceCard