import * as actions from 'stores/actions'

import { useEffect, useRef, useState } from 'react'
import { AiOutlineEdit, AiOutlineMore } from 'react-icons/ai'
import { MdClose, MdOutlineCancel } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { BookingAPI, CommentAPI, SalonAPI } from 'stores/apis'
import BookingCommentModel, { BookingCommentContentType } from 'stores/models/booking-comment.models'
import BookingModel, { BookingPromotionType, BookingPromotionTypeName, BookingStatus, BookingStatusButton, BookingStatusNames } from 'stores/models/booking.models'
import { convertTzToUtc, formatDate, formatDateTz } from 'utils/times'

import images from 'assets'
import { AxiosResponse } from 'axios'
import { Wrapper } from 'components'
import { envConfigs } from 'configs/env.config'
import { useTranslation } from 'react-i18next'
import { BsFillCameraFill } from 'react-icons/bs'
import { RiSendPlaneFill } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import { ReduxState } from 'stores/models'
import BookingServiceModel from 'stores/models/booking-service.models'
import SalonModel from 'stores/models/salon.models'
import { SalonStaffRole } from 'stores/models/staff.models'
import { formatCurrency } from 'utils/currency'
import { modalRef } from 'utils/refs'
import AddServices from '../add-service'
import ChangeSchedule from '../change-schedule'
import ChooseTechnician from '../choose-technician'
import AddExtraService from './../add-extra-service/index'
import BookingServiceCard from './components/booking-service-card'
import useStyles from './styles'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'unset !important'
  }
}

interface StateInterface {
  showChargeDiscount: boolean,
  promotionType: number,
  promotionValue: number,
  surcharge: number,
  showDateTimePicker: boolean,
  comment: string,
  images: any[],
  commentEdit: any,
  contentIds: any[],
}

const Confirm = (props: any) => {
  const styles = useStyles()

  const { message, callback, cancelButtonText, confirmButtonText, t } = props

  const onCloseModal = () => modalRef?.current?.close()

  return (
    <div className={styles.confirmContent}>
      <h6>{message}</h6>
      <div className={styles.groupButton}>
        <button onClick={onCloseModal} className={styles.buttonCancel}>
          {cancelButtonText ?? t('cancel')}
        </button>
        {callback &&
          <button onClick={callback} className={styles.buttonConfirm}>
            {confirmButtonText ?? t('confirm')}
          </button>
        }
      </div>
    </div>
  )
}

const PromotionValueOptions = (props: any) => {
  const styles = useStyles()

  const { onSelect } = props

  return (
    <>
      <div className={styles.promotionValueOptionTitle}>
        Choose promotion type
      </div>
      <div className={styles.promotionValueOptions}>
        {Object.values(BookingPromotionType).map((item: any) => {
          return (
            <div key={item} className={styles.promotionValueOption} onClick={() => onSelect(item)}>
              <span>{BookingPromotionTypeName[item]}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}

const Booking = () => {
  const fileRef = useRef<any>();
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const styles = useStyles()
  const limit = 10

  const { salonId, bookingId }: any = useParams()

  const { salon, bookings, booking, user, timezones, technicians, bookingComments } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    bookings: state.bookings.bookings,
    booking: state.bookings.booking,
    user: state.users.user,
    timezones: state.app.timezones,
    technicians: state.staffs.technicians,
    bookingComments: state.bookings.bookingComments,
  }))

  const [state, setState] = useState<StateInterface>({
    showChargeDiscount: false,
    promotionType: BookingPromotionType.Value,
    promotionValue: 0,
    surcharge: 0,
    showDateTimePicker: false,
    comment: '',
    images: [],
    commentEdit: {},
    contentIds: [],
  })
  const handleStateChange = (value: string | any | boolean, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  useEffect(() => {
    if (user && salonId > 0) {
      getSalon(salonId)
    }
  }, [user, salonId])

  const getSalon = async (salonId: any) => {
    if (salonId > 0) {
      const response: any = await SalonAPI.getSalon(salonId)
      if (response?.data?.status === 200) {
        dispatch({ type: actions.SET_CURRENT_SALON, payload: new SalonModel(response.data.data) })
        dispatch({ type: actions.GET_TECHNICIANS, payload: { salonId, role: SalonStaffRole.Technician } })
        dispatch({ type: actions.GET_SERVICES, payload: { salonId } })
        dispatch({ type: actions.GET_BOOKINGS_SUCCESS, payload: [] })
        dispatch({ type: actions.GET_BOOKINGS_REQUEST, payload: { salonId } })
        dispatch({ type: actions.GET_BOOKING_COMMENTS, payload: { bookingId } })
      }
    }
  }

  useEffect(() => {
    if (user && bookingId > 0) {
      dispatch({ type: actions.GET_BOOKING_DETAIL, payload: bookingId })
    }
  }, [user, salon, bookingId])

  if (!booking || !salon?.timezone) return null

  const disabledConfirm = [BookingStatus.Canceled, BookingStatus.Done].includes(Number(booking?.status))
  const disabledAddDeleteService = [BookingStatus.Canceled, BookingStatus.Done].includes(Number(booking?.status))
  const subTotal = booking?.bookingServices?.reduce((total: number, item: any) => total + item.price, 0) || 0

  let promotionValue: any = 0
  if (booking?.promotionType === BookingPromotionType.Percent) {
    promotionValue = booking?.promotionValue ? '-' + subTotal * (booking?.promotionValue / 100) : 0
  } else {
    promotionValue = booking?.promotionValue ? '-' + booking?.promotionValue : 0
  }

  let total = subTotal + (booking?.surcharge || 0)
  if (Number(booking?.promotionType) === BookingPromotionType.Percent) {
    total -= subTotal * (booking?.promotionValue / 100)
  } else {
    total -= booking?.promotionValue
  }

  const bookingStatusTime = booking?.bookingStatuses?.find((i: any) => Number(i?.status) === Number(booking?.status))

  const isUpdateSchedule: boolean = booking?.status === BookingStatus.Pending
  const salonTz: any = timezones.find(item => item.id === salon?.timezoneId)

  const openModalChangeTechnician = (bookingServiceId: number, technician: any) => {
    modalRef?.current?.open({
      content: <ChooseTechnician bookingServiceId={bookingServiceId} technicians={technicians} selected={technician} callback={onSelectTechnician} onCloseModal={onCloseModal} />,
    })
  }

  const onSelectTechnician = async (bookingServiceId: number, technician: any) => {
    const params = {
      bookingId: booking?.id,
      bookingServiceId: bookingServiceId,
      technician_id: technician?.id,
    }

    const response: AxiosResponse<any> | undefined = await BookingAPI.updateBookingService(params)
    if (response?.data?.status === 200) {
      const newBooking = new BookingModel(response.data.data)
      dispatch({ type: actions.GET_BOOKING_DETAIL_SUCCESS, payload: newBooking })
    }
    modalRef?.current?.close()
  }

  const openModalAddService = () => {
    modalRef?.current?.open({
      content: <AddServices callback={onAddService} onCloseModal={onCloseModal} />,
    })
  }

  const onAddService = async (service: any, technician: any) => {
    const params = {
      bookingId: booking?.id,
      technician_id: technician?.id,
      service_id: service?.id,
    }

    const response: AxiosResponse<any> | undefined = await BookingAPI.addBookingService(params)
    if (response?.data?.status === 200) {
      const newBooking = new BookingModel(response?.data?.data)
      dispatch({ type: actions.GET_BOOKING_DETAIL_SUCCESS, payload: newBooking })
    }
    modalRef?.current?.close()
  }

  const openModalAddExtraService = () => {
    modalRef?.current?.open({
      content: <AddExtraService bookingId={booking?.id} onCloseModal={onCloseModal} />,
    })
  }

  const showDateTimePicker = () => {
    modalRef?.current?.open({
      content: <ChangeSchedule callback={onChangeSchedule} />,
      customModalStyles: customStyles,
    })
  }

  const onChangeSchedule = async (value: any) => {
    const params = {
      bookingId: booking?.id,
      startDateTime: convertTzToUtc(value, salonTz?.timezone, 'YYYY-MM-DD HH:mm:ss'),
    }
    const response: AxiosResponse<any> | undefined = await BookingAPI.updateBooking(params)
    if (response?.data?.status === 200) {
      const newBooking = new BookingModel(response.data.data)
      dispatch({ type: actions.GET_BOOKING_DETAIL_SUCCESS, payload: newBooking })
    } else {

    }
    onCloseModal()
  }

  const confirmUpdateBookingStatus = (status: BookingStatus, params?: any) => {
    let message = ''
    switch (status) {
      case BookingStatus.Canceled:
        message = t('are_you_want_to_this_booking', { status: Number(booking?.status) !== BookingStatus.Pending ? t('cancel') : t('reject') })
        break
      case BookingStatus.Confirmed:
        message = t('are_you_want_to_this_booking', { status: t('confirm') })
        break
      case BookingStatus.CheckedIn:
        message = t('are_you_want_to_this_booking', { status: t('check_in') })
        break
      case BookingStatus.Done:
        message = t('are_you_want_to_this_booking', { status: t('check_out') })
        break
      default:
        message = t('are_you_want_to_update_status_booking')
        break
    }

    modalRef?.current?.open({
      content: <Confirm message={message} callback={() => updateBooking(status, params)} t={t} />
    })
  }

  const updateBooking = async (status: BookingStatus, params?: any) => {
    let data: any = {
      bookingId: Number(booking?.id),
      status,
    }

    if (status === BookingStatus.Done) {
      const regex = /^[+-]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/
      if (!regex.test(state.surcharge.toString())) {
        modalRef?.current?.open({
          content: <Confirm message={t('surcharge_must_be_number')} callback={() => updateBooking(status, params)} t={t} />
        })
        return
      }

      if (!regex.test(state.promotionValue.toString())) {
        modalRef?.current?.open({
          content: <Confirm message={t('promotion_must_be_number')} callback={() => updateBooking(status, params)} t={t} />
        })
        return
      }

      const checkBookingServiceNonTechnician = booking?.bookingServices?.find((service: BookingServiceModel) => !service.technicianId)
      if (checkBookingServiceNonTechnician) {
        modalRef?.current?.open({
          content: <Confirm message={t('choose_technician_for_all_services')} callback={() => updateBooking(status, params)} t={t} />
        })
        return
      }


      const { promotionType, promotionValue, surcharge } = state
      const subTotal = booking?.bookingServices?.reduce((total: number, item: any) => total + item.price, 0) || 0

      if (promotionType === BookingPromotionType.Percent && promotionValue > 100) {
        modalRef?.current?.open({
          content: <Confirm message={t('promotion_must_be_less_than')} callback={() => updateBooking(status, params)} t={t} />
        })
        return
      }

      if (promotionType === BookingPromotionType.Value && promotionValue > subTotal) {
        modalRef?.current?.open({
          content: <Confirm message={t('promotion_must_be_less_than_sub_total')} callback={() => updateBooking(status, params)} t={t} />
        })
        return
      }

      data = {
        ...data,
        promotionType,
        promotionValue,
        surcharge,
      }
    }

    let message = ''
    switch (status) {
      case BookingStatus.Canceled:
        message = t('reject_successfully')
        break
      case BookingStatus.Confirmed:
        message = t('confirm_successfully')
        break
      case BookingStatus.CheckedIn:
        message = t('check_in_successfully')
        break
      case BookingStatus.Done:
        message = t('check_out_successfully')
        break
      default:
        message = t('check_out_successfully')
        break
    }

    const response: AxiosResponse<any> | undefined = await BookingAPI.updateBooking(data)
    if (response?.data?.status === 200) {
      const newBooking = new BookingModel(response.data.data)
      dispatch({ type: actions.GET_BOOKING_DETAIL_SUCCESS, payload: newBooking })

      const newBookings = [...bookings]
      const bIndex = newBookings.findIndex((b: BookingModel) => b.id === newBooking.id)
      if (bIndex > -1) {
        newBookings[bIndex] = newBooking
        dispatch({ type: actions.GET_BOOKINGS_SUCCESS, payload: newBookings })
      }

      modalRef?.current?.open({
        content: <Confirm message={message} cancelButtonText={'close'} t={t} />
      })
    }
  }

  const onDeleteBookingService = async (bookingServiceId: number) => {
    const response: AxiosResponse<any> | undefined = await BookingAPI.deleteBookingService(Number(booking?.id), Number(bookingServiceId))
    if (response?.data?.status === 200) {
      const newBooking = { ...booking, bookingServices: booking?.bookingServices?.filter((service: BookingServiceModel) => service.id !== bookingServiceId) }
      dispatch({ type: actions.GET_BOOKING_DETAIL_SUCCESS, payload: newBooking })
    }
  }

  const onChangeShowChargeDiscount = (e: any) => {
    const checked = e.target.checked
    handleStateChange(checked, 'showChargeDiscount')
  }

  const onChangePromotionType = (value: string) => {
    handleStateChange(value, 'promotionType')
    onCloseModal()
  }

  const onChangePromotionValue = (value: number) => {
    const regex = /^[+-]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/
    return regex.test(value.toString()) ? handleStateChange(value, 'promotionValue') : t('promotion_must_be_number')
  }

  const onChangeSurcharge = (value: number) => {
    const regex = /^[+-]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/
    return regex.test(value.toString()) ? handleStateChange(value, 'surcharge') : t('surcharge_must_be_number')
  }

  const onShowPromotionType = () => {
    modalRef?.current?.open({
      content: <PromotionValueOptions onSelect={onChangePromotionType} />,
    })
  }

  const onChangeInput = (e: any) => {
    const { name, value } = e.target
    handleStateChange(value, name)
  }

  const onCloseModal = () => {
    modalRef?.current?.close()
  }

  const openSelectFile = () => {
    fileRef?.current?.click()
  }

  const handleFileChange = (e: any) => {
    const { name, files } = e.target
    const newFiles: any = [...state.images]
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        newFiles.push(file)
      }
      handleStateChange(newFiles, name)
    }
  };

  const onDeleteFile = (image: any) => {
    const newFiles = [...state.images]
    const index = newFiles.findIndex((f: any) => (f?.raw && f?.raw === image?.raw) || (f?.name && f?.name === image?.name))
    if (index > -1) {
      newFiles.splice(index, 1)
      handleStateChange(newFiles, 'images')
    }

    const newContentIds = state.contentIds.filter((c: any) => c !== image?.id)
    handleStateChange(newContentIds, 'contentIds')
  }

  const sendMessage = async () => {
    const { comment, images, commentEdit, contentIds } = state
    const params: any = {
      bookingId: booking?.id,
      text: comment,
    }

    const files = images

    if (commentEdit?.id) {
      params.commentId = Number(commentEdit?.id || 0)
      params.contentIds = contentIds.join(',')

      const response: AxiosResponse<any> | undefined = await CommentAPI.updateComment(params, files)
      if (response?.data?.status === 200) {
        const newComment = new BookingCommentModel(response.data.data)
        const newComments = [...bookingComments]
        const index = newComments.findIndex((c: BookingCommentModel) => c.id === newComment.id)
        if (index > -1) {
          newComments[index] = newComment
          dispatch({ type: actions.GET_BOOKING_COMMENTS_SUCCESS, payload: newComments })
        }
        handleStateChange('', 'comment')
        handleStateChange([], 'images')
        handleStateChange({}, 'commentEdit')
      }
    } else {
      const response: AxiosResponse<any> | undefined = await CommentAPI.addComment(params, files)
      if (response?.data?.status === 200) {
        const newComment = new BookingCommentModel(response.data.data)
        const newComments = [...bookingComments]
        newComments.unshift(newComment)
        dispatch({ type: actions.GET_BOOKING_COMMENTS_SUCCESS, payload: newComments })
        handleStateChange('', 'comment')
        handleStateChange([], 'images')
        handleStateChange({}, 'commentEdit')
      }
    }
  }

  const onEditComment = (comment: BookingCommentModel) => {
    handleStateChange(comment, 'commentEdit')
    const message = comment.contents.find((content: any) => content.type === BookingCommentContentType.Comment)
    handleStateChange(message?.content, 'comment')

    const images = comment.contents.reduce((acc: any, content: any) => {
      if (content.type === BookingCommentContentType.Images) {
        const images = JSON.parse(content.content)
        const newImages = { ...images, id: content.id }
        acc.push(newImages)
      }
      return acc
    }, [])
    handleStateChange(images, 'images')

    const contentIds = comment.contents.map((content: any) => content.id)
    handleStateChange(contentIds, 'contentIds')
  }

  const cancelEditComment = () => {
    handleStateChange('', 'commentEdit')
    handleStateChange([], 'contentIds')
    handleStateChange('', 'comment')
    handleStateChange([], 'images')
  }

  const onDeleteComment = async (comment: BookingCommentModel) => {
    const response: AxiosResponse<any> | undefined = await CommentAPI.deleteComment(Number(booking?.id), Number(comment?.id))
    if (response?.data?.status === 200) {
      const newComments = bookingComments.filter((c: BookingCommentModel) => c.id !== comment?.id)
      dispatch({ type: actions.GET_BOOKING_COMMENTS_SUCCESS, payload: newComments })
    }
  }

  return (
    <Wrapper className={styles.container}>
      <div className={styles.content}>
        <div className={styles.sectionHeader}>
          <div className={styles.customerInfo}>
            <div className={styles.customerName}>
              {booking?.name}
            </div>
            <div className={styles.customerPhone}>
              {booking?.phone}
            </div>
          </div>
          <div className={styles.customerAvatar}>
            <img className={styles.customerAvatarImg} src={images.customerDefault()} alt="avatar" />
          </div>
        </div>

        <div className={styles.sectionList}>
          {booking?.bookingCode &&
            <div className={styles.contentRow}>
              <div className={styles.rowTitle}>
                {t('booking_code')}
              </div>
              <div className={styles.rowContent}>
                {booking?.bookingCode}
              </div>
            </div>
          }

          <div className={styles.contentRow}>
            <div className={styles.rowTitle}>
              {t('status')}
            </div>
            <div className={styles.rowContent}>
              {t(BookingStatusNames[booking?.status]) ?? t('pending')} {bookingStatusTime && salon?.timezone?.timezone ? '(' + formatDateTz(bookingStatusTime?.created_at, salon?.timezone?.timezone, 'DD/MM/YYYY HH:mm') + ')' : ''}
            </div>
          </div>

          <div className={styles.contentRow}>
            <div className={styles.rowTitle}>
              {t('schedule')}
            </div>
            <div className={styles.rowContent}>
              {booking?.startDateTime && salonTz?.timezone ? formatDateTz(booking?.startDateTime, salon?.timezone?.timezone, 'HH:mm dddd MMM DD, YYYY') : ''}
              {isUpdateSchedule &&
                <span style={styles.contentSpace} onClick={showDateTimePicker}>
                  <AiOutlineEdit size={18} style={{ marginLeft: '1rem', color: '#578688' }} />
                </span>
              }
            </div>
          </div>

          <div className={styles.contentRow}>
            <div className={styles.rowTitle}>
              <div className={styles.f1}>{t('services')}</div>

              {!disabledAddDeleteService &&
                <>
                  <div className={styles.addExtraService} onClick={openModalAddExtraService}>{t('add_extra_service')}</div>
                  <div className={styles.addService} onClick={openModalAddService}>{t('add_service')}</div>
                </>
              }

            </div>
            <div className={styles.rowContent}>
              {
                booking?.bookingServices?.map((service: any) => (
                  <BookingServiceCard key={service.id} item={service} disabledDeleteService={disabledAddDeleteService} openModalChangeTechnician={openModalChangeTechnician} onDeleteBookingService={onDeleteBookingService} />
                ))
              }
            </div>
          </div>

          {Number(booking?.status) !== BookingStatus.Done &&
            <div className={styles.contentRowInline}>
              <div className={styles.rowTitle}>
                {t('sub_total')}
              </div>
              <div className={styles.rowContentRight}>
                {formatCurrency(subTotal.toString(), salon?.timezone?.currency)}
              </div>
            </div>
          }

          {Number(booking?.status) === BookingStatus.Done &&
            <>
              <div className={styles.contentRowInline}>
                <div className={styles.rowTitle}>
                  {t('sub_total')}
                </div>
                <div className={styles.rowContentRight}>
                  {formatCurrency(subTotal.toString(), salon?.timezone?.currency)}
                </div>
              </div>

              <div className={styles.contentRowInline}>
                <div className={styles.rowTitle}>
                  {t('surcharge')}
                </div>
                <div className={styles.rowContentRight}>
                  {'+ ' + formatCurrency((booking?.surcharge ?? 0).toString(), salon?.timezone?.currency)}
                </div>
              </div>

              <div className={styles.contentRowInline}>
                <div className={styles.rowTitle}>
                  {t('promotion')}
                </div>
                <div className={styles.rowContentRight}>
                  {formatCurrency(promotionValue, salon?.timezone?.currency)}
                </div>
              </div>

              <div className={styles.contentRowInline}>
                <div className={styles.rowTitle}>
                  {t('total')}
                </div>
                <div className={styles.rowContentRight}>
                  {formatCurrency(total.toString(), salon?.timezone?.currency)}
                </div>
              </div>
            </>
          }

          {booking?.note &&
            <div className={styles.contentRow}>
              <div className={styles.rowTitle}>
                {t('notes')}
              </div>
              <div className={styles.rowContent}>
                {booking?.note}
              </div>
            </div>
          }

          {Number(booking?.status) === BookingStatus.CheckedIn &&
            <>
              <div className={styles.rowCheckBox}>
                <div className="form-check">
                  <input className={`form-check-input ${styles.checkBox}`} type="checkbox" checked={state.showChargeDiscount} onChange={onChangeShowChargeDiscount} />
                  <label className="form-check-label">
                    {t('surcharge_promotion')}
                  </label>
                </div>
              </div>

              {state.showChargeDiscount &&
                <>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">{t('surcharge')}</label>
                    <input type="number" className="form-control" placeholder="Surcharge" name="surcharge" value={state.surcharge} onChange={onChangeInput} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">{t('promotion')}</label>
                    <div className={styles.groupPromotion}>
                      <input type="per" className={`form-control ${styles.promotion}`} placeholder="Promotion" name="promotionValue" value={state.promotionValue} onChange={onChangeInput} />
                      <div className={styles.chargeDiscount} onClick={onShowPromotionType}>
                        <span>{state.promotionType === BookingPromotionType.Percent ? '%' : '$'}</span>
                      </div>
                    </div>
                  </div>
                </>
              }
            </>
          }
        </div>

        {bookingComments.length > 0 &&
          <div className={styles.contentRow}>
            <div className={styles.rowTitle}>
              {t('comments')}
            </div>
            <div className={styles.comments}>
              {bookingComments?.map((comment: any) => (
                <div key={comment.id} className={styles.comment}>
                  <div className={styles.commentBody}>
                    <div className={styles.commentAvatar}>
                      <img className={styles.commentAvatarImg} src={comment.avatar} alt="avatar" />
                    </div>

                    <div className={styles.commentContent}>
                      <div className={styles.commentContentText}>
                        <div className={styles.commentInfo}>
                          <div className={styles.commentAuthor}>
                            {comment?.user?.name}
                          </div>

                          <div className={styles.commentDate}>
                            {formatDate(comment?.createdAt, 'HH:mm DD/MM/YYYY')}
                          </div>

                          {Number(comment?.userId) === Number(user?.id) &&
                            <div className="dropdown" style={{ marginLeft: '10px' }}>
                              <div data-bs-toggle="dropdown" aria-expanded="false">
                                <AiOutlineMore size={18} />
                              </div>
                              <ul className="dropdown-menu">
                                <li><span className="dropdown-item" onClick={() => onEditComment(comment)}>{t('edit')}</span></li>
                                <li><span className="dropdown-item" onClick={() => onDeleteComment(comment)}>{t('delete')}</span></li>
                              </ul>
                            </div>
                          }
                        </div>

                        <div className={styles.commentText}>
                          {
                            comment?.contents?.map((content: any) => content.type === 1 && content.content)
                          }
                        </div>
                      </div>

                      <div className={styles.commentImages}>
                        {
                          comment?.contents?.map((content: any) => content.type === 2 && <img className={styles.commentImage} key={content.id} src={`${envConfigs.IMAGE_LINK}/bookings/comments/${JSON.parse(content.content).thumbnail}`} alt="comment" />)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }

        <div className={styles.contentRow}>
          <div className={styles.messageInput}>
            <input type="file" name="images" multiple ref={fileRef} className={styles.fileInput} onChange={handleFileChange} />
            <div className="btn waves-effect waves-light send" onClick={openSelectFile}><BsFillCameraFill size={26} /></div>
            <div className={styles.groupInput}>
              <div className={styles.viewImages}>
                {state.images.map((image: any, index: number) => (
                  <div key={index} className={styles.imageItem}>
                    <img className={styles.image} src={image?.name ? URL.createObjectURL(image) : `${envConfigs.IMAGE_LINK}/bookings/comments/${image.thumbnail}`} alt="images" />
                    <span className={styles.deleteImage} onClick={() => onDeleteFile(image)}> <MdClose size={18} /> </span>
                  </div>
                ))}
              </div>
              <input type="text" className={`form-control ${styles.messageInputText}`} placeholder="Type comment here.." name="comment" value={state.comment} onChange={onChangeInput} />
            </div>
            {state?.commentEdit?.id &&
              <div className="btn waves-effect waves-light send text-red" onClick={cancelEditComment}><MdOutlineCancel size={26} /></div>
            }
            <div className="btn waves-effect waves-light send" onClick={sendMessage}><RiSendPlaneFill size={26} /></div>
          </div>
        </div>

        {!disabledConfirm &&
          <div className={styles.sectionFooter}>
            <button className={styles.modalButtonClose} onClick={() => confirmUpdateBookingStatus(BookingStatus.Canceled)}>
              <span>{Number(booking?.status) !== BookingStatus.Pending ? t('cancel') : t('reject')}</span>
            </button>

            <button className={styles.modalButton} onClick={() => confirmUpdateBookingStatus(booking?.status + 1)}>
              <span>{t(BookingStatusButton[booking?.status + 1]?.toLowerCase())}</span>
            </button>
          </div>
        }
      </div>
    </Wrapper>
  )
}

export default Booking
