import FadeLoader from 'react-spinners/FadeLoader'
import useStyles from "./styles"

export const Loading = () => {
  const styles = useStyles()

  return (
    <div className={styles.wrapper}>
      <FadeLoader
        loading={true}
        color='#578688'
      />
    </div>
  )
}
