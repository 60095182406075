import * as actions from '../actions';

import { BookingsState } from './../models/redux-state.models';

const initState: BookingsState = {
  loading: false,
  bookings: [],
  booking: null,
  todayBookings: [],
  pendingBookings: [],
  customerBookings: [],
  bookingComments: [],
}

const bookings = (state = initState, action: any) => {
  switch (action.type) {

    case actions.GET_BOOKINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: action.payload
      };
    case actions.GET_BOOKINGS_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.GET_BOOKING_DETAIL:
      return {
        ...state,
        loading: true
      };
    case actions.GET_BOOKING_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        booking: action.payload
      };
    case actions.GET_BOOKING_DETAIL_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.GET_BOOKING_STATICS:
      return {
        ...state,
        loading: true
      };
    case actions.GET_BOOKING_STATICS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookingStatics: action.payload
      };
    case actions.GET_BOOKING_STATICS_FAILED:
      return {
        ...state,
        loading: false
      };

    // today bookings
    case actions.GET_TODAY_BOOKINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.GET_TODAY_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        todayBookings: action.payload
      };
    case actions.GET_TODAY_BOOKINGS_FAILED:
      return {
        ...state,
        loading: false
      };

    // today bookings static
    case actions.GET_TODAY_BOOKING_STATICS:
      return {
        ...state,
        loading: true
      };
    case actions.GET_TODAY_BOOKING_STATICS_SUCCESS:
      return {
        ...state,
        loading: false,
        todayBookingStatics: action.payload
      };
    case actions.GET_TODAY_BOOKING_STATICS_FAILED:
      return {
        ...state,
        loading: false
      };

    // pending bookings
    case actions.GET_PENDING_BOOKINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.GET_PENDING_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingBookings: action.payload
      };
    case actions.GET_PENDING_BOOKINGS_FAILED:
      return {
        ...state,
        loading: false
      };

    // pending bookings
    case actions.GET_CUSTOMER_BOOKINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.GET_CUSTOMER_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerBookings: action.payload
      };
    case actions.GET_CUSTOMER_BOOKINGS_FAILED:
      return {
        ...state,
        loading: false
      };

    // booking comments
    case actions.GET_BOOKING_COMMENTS:
      return {
        ...state,
        loading: true
      };
    case actions.GET_BOOKING_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookingComments: action.payload
      };
    case actions.GET_BOOKING_COMMENTS_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.LOG_OUT:
      return initState

    default:
      return state;
  }
};

export default bookings