// eslint-disable-next-line no-use-before-define

import * as actions from 'stores/actions';

import { Loading, Wrapper } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getStartOfDateTz } from 'utils/times';
import images from 'assets/';
import EmptyState from 'components/empty-state';
import BookingCard from 'containers/home/components/booking-card';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { SalonAPI } from 'stores/apis';
import { ReduxState } from 'stores/models';
import SalonModel from 'stores/models/salon.models';
import { SalonStaffRole } from 'stores/models/staff.models';
import useStyles from './styles';

const UpcomingBookings = () => {
  const dispatch = useDispatch()
  const { t, i18n }: any = useTranslation()
  const styles = useStyles()
  const navigate: any = useNavigate()
  const limit = 10

  const { salonId } = useParams()

  const { salon, bookings, user, staffRole, timezones } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    bookings: state.bookings.bookings,
    user: state.users.user,
    staffRole: state.staffs.staffRole,
    timezones: state.app.timezones,
  }))

  const salonTz: any = timezones.find(item => item.id === salon?.timezoneId)

  const [state, setState] = useState({
    loading: false,
    bookingList: [],
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))

  const getSalon = async (salonId: any) => {
    handleStateChange({ loading: true })
    const response: any = await SalonAPI.getSalon(salonId)
    if (response?.data?.status === 200) {
      dispatch({ type: actions.SET_CURRENT_SALON, payload: new SalonModel(response.data.data) })
      dispatch({ type: actions.GET_TECHNICIANS, payload: { salonId, role: SalonStaffRole.Technician } })
      dispatch({ type: actions.GET_STAFF_ROLE, payload: { salonId, userId: user?.id } })
    }
    handleStateChange({ loading: false })
  }

  useEffect(() => {
    getSalon(salonId)
  }, [user, salonId])

  useEffect(() => {
    dispatch({ type: actions.GET_BOOKINGS_SUCCESS, payload: [] })
    dispatch({ type: actions.GET_BOOKINGS_REQUEST, payload: { salonId, technicianIds: Number(staffRole?.technicianId) > 0 ? [staffRole?.technicianId].join(',') : null, minStartDateTime: getStartOfDateTz(moment().add(1, 'days'), salonTz?.timezone) } })
  }, [user, salonId, staffRole])

  useEffect(() => {
    handleStateChange({ bookingList: bookings.sort((a: any, b: any) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime()) })
  }, [bookings])

  return (
    <Wrapper className={styles.container}>
      {state.loading && <Loading />}
      <div className={styles.sectionList} >
        <div className={styles.sectionListHeader}>
          <div className={styles.sectionListHeaderTitle}>
            <p>{t('upcoming_bookings')}</p>
          </div>
        </div>

        <div className={styles.bookingList}>
          {state.bookingList.length > 0 ?
            <>
              {state.bookingList?.map((booking: any) => (
                <BookingCard key={booking.id} booking={booking} />
              ))}
            </>
            :
            <EmptyState imgUrl={images.bookingEmpty()} text="No bookings" />
          }
        </div>
      </div >
    </Wrapper>
  )
}

export default UpcomingBookings
