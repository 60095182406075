import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxHeight: '80vh',
    width: '700px',
  },

  f1: {
    flex: 1,
  },

  // technician Modal List
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px',
    fontWeight: '700',
    color: '#578688',
    borderBottom: '1px solid #578688',
    fontSize: '20px',
    marginBottom: '20px',
  },

  modalList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '700px',
    height: '100%',
    overflow: 'auto',
  },

  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px 20px',
    borderTop: '1px solid #578688',
    marginTop: '20px',
  },

  modalButton: {
    display: 'flex',
    padding: '8px 12px',
    fontWeight: '700',
    borderRadius: '10px',
    color: '#fff',
    backgroundColor: '#578688',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    marginLeft: '15px',
  },

  modalButtonClose: {
    display: 'flex',
    padding: '8px 12px',
    fontWeight: '700',
    borderRadius: '10px',
    color: '#578688',
    backgroundColor: '#fff',
    border: '1px solid #578688',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },

  //technician
  techInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
  },

  techAvatar: {
    width: '36px',
    height: '36px',
    borderRadius: '8px',
  },

  techAvatarImg: {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
  },

  techName: {
    fontSize: '14px',
    color: '#3C3C3E',
    fontWeight: '700',
    cursor: 'pointer',
    alignItems: 'center',
    textAlign: 'center',
  },
})

export default useStyles
