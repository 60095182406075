import * as actions from 'stores/actions'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AxiosResponse } from 'axios'
import { TechnicianAvatar } from 'components/technician-avatar'
import { useTranslation } from 'react-i18next'
import { BookingAPI } from 'stores/apis'
import { ReduxState } from 'stores/models'
import BookingModel from 'stores/models/booking.models'
import { SalonStaffRole } from 'stores/models/staff.models'
import ChooseTechnician from '../choose-technician'
import useStyles from './styles'

interface InputState {
  name: string
  price: any
  duration: string
  technician: any
  showSelectTechnician: boolean
}


const AddExtraService = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const styles = useStyles()

  const { bookingId, onCloseModal } = props

  const [state, setState] = useState<InputState>({
    name: '',
    price: '',
    duration: '',
    technician: {},
    showSelectTechnician: false,
  })
  const handleStateChange = (value: string | string[] | boolean | number, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  const { user, salon, technicians } = useSelector((state: ReduxState) => ({
    user: state.users.user,
    salon: state.salons.salon,
    technicians: state.staffs.technicians,
  }))

  useEffect(() => {
    dispatch({ type: actions.GET_SERVICES, payload: { salonId: salon?.id } })
    dispatch({ type: actions.GET_TECHNICIANS, payload: { salonId: salon?.id, role: SalonStaffRole.Technician } })
  }, [user, salon])

  const onServiceSelected = (service: any) => {
    handleStateChange(service, 'service')
  }

  const onAddExtraService = async () => {
    const { name, price, duration, technician } = state
    if (!name || !price || !duration || !technician) return

    setState(prevState => ({ ...prevState, loading: true }))

    const data = {
      bookingId,
      name,
      price,
      duration,
      technician_id: technician.id,
    }

    try {
      const response: AxiosResponse<any> | undefined = await BookingAPI.addExtraService(data)
      if (response?.data?.status === 200) {
        const newBooking = new BookingModel(response?.data?.data)
        dispatch({ type: actions.GET_BOOKING_DETAIL_SUCCESS, payload: newBooking })
        onCloseModal()
      }
    } catch (error) {
      setState(prevState => ({ ...prevState, loading: false }))
    }
  }

  const onChangeInput = (e: any) => {
    const { name, value } = e.target
    handleStateChange(value, name)
  }

  const openModalChangeTechnician = () => handleStateChange(true, 'showSelectTechnician')
  const closeModalChangeTechnician = () => handleStateChange(false, 'showSelectTechnician')

  const technicianSelected = (technician: any) => {
    handleStateChange(technician, 'technician')
    handleStateChange(false, 'showSelectTechnician')
  }

  return (
    <div className={styles.container}>
      {!state.showSelectTechnician &&
        <>
          <div className={styles.modalHeader}>
            {t('add_extra_service')}
          </div>
          <div className="form-login">
            <div className="form-group">
              <label htmlFor="email">{t('name')}</label>
              <input type="text" className="form-control" name="name" placeholder={t('name')} value={state.name} onChange={onChangeInput} />
            </div>

            <div className="form-group">
              <label htmlFor="price">{t('price')}</label>
              <input type="text" className="form-control" name="price" placeholder={t('price')} value={state.price} onChange={onChangeInput} />
            </div>

            <div className="form-group">
              <label htmlFor="duration">{t('duration')}</label>
              <input type="text" className="form-control" name="duration" placeholder={t('duration')} value={state.duration} onChange={onChangeInput} />
            </div>

            <div className="form-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <label className={styles.f1}>{t('technician')}</label>
              <div style={{ marginTop: '15px', marginBottom: '5px', color: '#578688' }} onClick={openModalChangeTechnician}>{t('choose_technician')}</div>
            </div>

            {state.technician.name &&
              <div className={styles.techInfo}>
                <div className={styles.techAvatar}>
                  <TechnicianAvatar avatarUrl={state.technician?.avatarUrl ? state.technician?.avatarUrl() : null} technician={state?.technician} style={styles.techAvatar} />
                </div>
                <div className={styles.techName}>
                  {state?.technician?.name ? state?.technician?.name : t('choose_technician')}
                </div>
              </div>
            }

            <div className={styles.modalFooter}>
              <button className={styles.modalButtonClose} onClick={() => onCloseModal()}>
                <span>{t('close')}</span>
              </button>

              <button className={styles.modalButton} onClick={() => onAddExtraService()}>
                <span>{t('confirm')}</span>
              </button>
            </div>
          </div>
        </>
      }

      {state.showSelectTechnician &&
        <ChooseTechnician technicians={technicians} selected={state?.technician} onCloseModal={closeModalChangeTechnician} callback={technicianSelected} />
      }
    </div>
  )
}

export default AddExtraService
