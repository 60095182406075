import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    // booking card
    bookingList: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    bookingListItem: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '15px',
        marginBottom: '15px',
        cursor: 'pointer',
        position: 'relative',
    },

    bookingLeftInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '15px',
        justifyContent: 'center',
        alignItems: 'center',
    },

    customerAvatar: {
        width: '36px',
        height: '36px',
        borderRadius: '15px',
    },

    customerAvatarImg: {
        width: '100%',
        height: '100%',
    },

    duration: {
        fontWeight: 'bold',
    },

    bookingRightInfo: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        borderRadius: '15px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },

    groupBookingInfo: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '15px 10px',
    },

    bookingTechRow: {
        display: 'flex',
        flexDirection: 'row',
    },

    groupTechName: {
        flex: '1',
        display: 'flex',
        flexDirection: 'row',
    },

    techAvatar: {
        width: '25px',
        height: '25px',
        borderRadius: '15px',
    },

    techAvatarImg: {
        width: '25px',
        height: '25px',
    },

    bookingTechName: {
        marginLeft: '10px',
        wordBreak: 'break-all',
    },

    bookingTechPhone: {
        marginLeft: '10px',
    },

    bookingNote: {
        fontWeight: 'bold',
        paddingTop: '10px',
    },

    rectangle: {
        width: '8px',
        height: '100%',
        backgroundColor: '#dfe3e9',
        borderRadius: '15px 0 0 15px',
    },

    bookingStatus: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: '20px',
        top: '-14px',
        backgroundColor: '#ECCD99',
        padding: '4px',
        borderRadius: '5px',
        width: '90px',
        textAlign: 'center',
        zIndex: 5,
    },

    txtBookingStatus: {
        fontSize: '11px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
})

export default useStyles