export default {
  "english": "Tiếng Anh",
  "vietnamese": "Tiếng Việt",
  "hi": "Xin chào",
  "logout": "Đăng xuất",
  "welcome_back": "Chào mừng trở lại!",
  "password": "Mật khẩu",
  "forgot_password": "Quên mật khẩu",
  "login": "Đăng nhập",
  "forgot_password_description": "Nhập email của bạn để nhận OTP",
  "forgot_password_send_otp": "Gửi OTP đến email",
  "forgot_password_check_mail": "Vui lòng kiểm tra OTP trong hộp thư của bạn.",
  "forgot_password_expires": "Mã OTP sẽ hết hạn sau 10 phút",
  "resend": "Gửi lại trong",
  "enter_new_password": "Nhập mật khẩu mới",
  "reset_password": "Đặt lại mật khẩu",
  "salon": "Salon",
  "no_salon": "Không có dữ liệu",
  "booking": "Đặt lịch",
  "today": "Hôm nay",
  "upcoming": "Sắp tới",
  "complete": "Hoàn thành",
  "cancelled": "Đã hủy",
  "pending": "Chờ duyệt",
  "confirmed": "Đã xác nhận",
  "checked_in": "Check in",
  "check_out": "Thanh toán",
  "done": "Hoàn thành",
  "reject": "Từ chối",
  "status": "Trạng thái",
  "schedule": "Lịch trình",
  "services": "Dịch vụ",
  "add_extra_service": "Thêm dịch vụ bổ sung",
  "add_service": "Thêm dịch vụ",
  "sub_total": "Tổng phụ",
  "surcharge": "Phụ phí",
  "promotion": "Khuyến mãi",
  "total": "Tổng",
  "notes": "Ghi chú",
  "surcharge_promotion": "Khuyến mãi phụ phí",
  "comments": "Bình luận",
  "edit": "Chỉnh sửa",
  "delete": "Xóa",
  "choose_technician": "Chọn thợ",
  "choose_technician_for_all_services": "Vui lòng chọn thợ cho tất cả dịch vụ",
  "promotion_must_be_less_than": "Khuyến mại phải nhỏ hơn 100%",
  "promotion_must_be_less_than_sub_total": "Giá trị khuyến mại phải nhỏ hơn Tổng số phụ",
  "reject_successfully": "Đã từ chối đặt chỗ thành công!",
  "confirm_successfully": "Đã xác nhận đặt chỗ thành công!",
  "check_in_successfully": "Khách hàng đã check in thành công!",
  "check_out_successfully": "Thanh toán thành công!",
  "update_booking_status_successfully": "Cập nhật trạng thái đặt chỗ thành công!",
  "promotion_must_be_number": "Khuyến mãi phải là một số",
  "surcharge_must_be_number": "Phụ phí phải là một số",
  "are_you_sure_logout": "Bạn có muốn đăng xuất không?",
  "cancel": "Hủy",
  "add_extra_service": "Thêm dịch vụ bổ sung",
  "name": "Tên",
  "price": "Giá",
  "duration": "Thời gian",
  "technician": "Thợ",
  "choose_technician": "Chọn thợ",
  "close": "Đóng",
  "confirm": "Xác nhận",
  "choose_service": "Chọn dịch vụ",
  "choose_schedule": "Chọn thời gian",
  "are_you_want_to_this_booking": "Bạn chắc chắn muốn {{status}} booking này?",
  "are_you_want_to_update_status_booking": "Bạn có chắc chắn muốn cập nhật trạng thái booking không?",
  "check_in": "check in",
  "check_out": "check out",
  "cancelled_bookings": "Booking bị hủy",
  "complete_bookings": "Booking hoàn thành",
  "upcoming_bookings": "Booking sắp tới",
  "today_bookings": "Booking hôm nay",
  "booking_code": "Mã đơn hàng"
}
