import * as actions from 'stores/actions'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { ReduxState } from 'stores/models'
import ChooseServicesCard from './components/choose-service-card'
import useStyles from './styles'

const ChooseServices = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const styles = useStyles()
  const [selected, setSelected] = useState(props?.selected)

  const { callback, onCloseModal } = props

  const { user, salon, services } = useSelector((state: ReduxState) => ({
    user: state.users.user,
    salon: state.salons.salon,
    services: state.services.services,
  }))

  useEffect(() => {
    dispatch({ type: actions.GET_SERVICES, payload: { salonId: salon?.id } })
  }, [user, salon])

  const onSelected = (technician: any) => {
    setSelected(technician)
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        {t('choose_service')}
      </div>
      <div className={styles.modalList}>
        {
          services?.map((service: any) => {
            return <ChooseServicesCard key={service.id} service={service} selected={selected} onSelected={onSelected} />
          })
        }
      </div>
      <div className={styles.modalFooter}>
        <button className={styles.modalButtonClose} onClick={() => onCloseModal()}>
          <span>{t('close')}</span>
        </button>

        <button className={styles.modalButton} onClick={() => callback(selected)}>
          <span>{t('confirm')}</span>
        </button>
      </div>
    </div>
  )
}

export default ChooseServices
