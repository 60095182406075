import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  wrapper: {
    paddingTop: '120px'
  },

  notAuthorizedNum: {
    color: '#fd7e14',
    fontSize: '80px',
    textAlign: 'center'
  },

  notAuthorizedText: {
    color: '#666',
    fontSize: '14px',
    textAlign: 'center',
    marginTop: '10px',
  }
})

export default useStyles
