import { Loading } from 'components/loading'
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ReduxState } from "stores/models"
import { modalRef } from "utils/refs"
import Header from './components/header'
import Modal from './components/modal'
import useStyles from "./styles"

interface WrapperProps {
  children: React.ReactNode
  className?: string,
  loadingDisabled?: boolean
}

export const Wrapper = (props: WrapperProps) => {
  const { children, className, loadingDisabled } = props
  const { isLoading, user } = useSelector((state: ReduxState) => ({
    isLoading: state.users.isLoading,
    user: state.users.user,
  }))

  const styles = useStyles()
  const { t } = useTranslation()

  if (isLoading && !loadingDisabled) return <Loading />

  if (!user) return (
    <div className={styles.wrapper}>
      <h1 className={styles.notAuthorizedNum}>403</h1>
      <h1 className={styles.notAuthorizedText}>{t('home.notAuthorizedPage')}</h1>
    </div>
  )

  return (
    <div className={className}>
      <Header />
      {children}
      <Modal ref={modalRef} />
    </div>
  )
}
