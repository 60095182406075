import * as actions from 'stores/actions'

import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import ChooseServices from '../choose-services'
import ChooseTechnician from '../choose-technician'
import { ReduxState } from 'stores/models'
import { SalonStaffRole } from 'stores/models/staff.models'
import useStyles from './styles'

interface InputState {
  service: any
}


const AddServices = (props: any) => {
  const dispatch = useDispatch()
  const styles = useStyles()

  const [state, setState] = useState<InputState>({
    service: null,
  })
  const handleStateChange = (value: string | string[] | boolean | number, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  const { callback, onCloseModal } = props

  const { user, salon, timezones, services, technicians } = useSelector((state: ReduxState) => ({
    user: state.users.user,
    salon: state.salons.salon,
    timezones: state.app.timezones,
    services: state.services.services,
    technicians: state.staffs.technicians,
  }))

  useEffect(() => {
    dispatch({ type: actions.GET_SERVICES, payload: { salonId: salon?.id } })
    dispatch({ type: actions.GET_TECHNICIANS, payload: { salonId: salon?.id, role: SalonStaffRole.Technician } })
  }, [user, salon])

  const onServiceSelected = (service: any) => {
    handleStateChange(service, 'service')
  }

  const onConfirm = (technician: any) => {
    if (state.service) {
      callback(state.service, technician)
    }
  }

  return (
    <div className={styles.container}>
      {!state.service && <ChooseServices callback={onServiceSelected} onCloseModal={onCloseModal} />}
      {state.service && <ChooseTechnician callback={onConfirm} onCloseModal={onCloseModal} />}
    </div>
  )
}

export default AddServices
