import './App.css'
import './locale'

import { Route, Routes } from 'react-router-dom'

import Authenticated from './Authenticated'
import ForgotPassword from './containers/forgot-password'
import Login from './containers/login'
import NotFound from './containers/not-found'

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/*" element={<Authenticated />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
